import {Route, Routes} from 'react-router-dom';
import Users from './Users';
import NotFound from './NotFound';
import Features from './Features';
import Bundles from './Bundles';

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Bundles />} />
      <Route path="/users" element={<Users />} />
      <Route path="/features" element={<Features />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
