import {Disclosure} from '@headlessui/react';
import {useNavigate} from 'react-router-dom';
import {classNames} from '../../HelperFunctions';
import {NavigationItem} from '../../types/Navigation';

interface ISidenav {
  navigation: NavigationItem[];
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SidenavMobile({navigation, setSidebarOpen}: ISidenav) {
  const navigate = useNavigate();

  return (
    <nav className="px-2 space-y-1">
      {navigation.map((item: NavigationItem) =>
        !item.children ? (
          <button
            key={item.name}
            onClick={() => {
              navigate(item.href);
              setSidebarOpen(false);
            }}
            className={
              'text-gray-300 hover:bg-gray-700 hover:text-white group w-full flex items-center px-2 py-2 text-sm font-medium rounded-md'
            }
          >
            <item.icon
              className={classNames(
                window.location.pathname === item.href
                  ? 'text-gray-300'
                  : 'text-gray-400 group-hover:text-gray-300',
                'mr-3 flex-shrink-0 h-6 w-6',
              )}
              aria-hidden="true"
            />
            {item.name}
          </button>
        ) : (
          <Disclosure
            defaultOpen
            as="div"
            key={item.name}
            className="space-y-1"
          >
            {({open}) => (
              <>
                <Disclosure.Button
                  className={classNames(
                    window.location.pathname === item.href
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                  )}
                >
                  <svg
                    className={classNames(
                      open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                      'mr-4 ml-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                    )}
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                  </svg>
                  {item.name}
                </Disclosure.Button>
                <Disclosure.Panel className="space-y-1">
                  {item.children &&
                    item.children.map((subItem: NavigationItem) => (
                      <button
                        key={subItem.name}
                        onClick={() => {
                          navigate(subItem.href);
                          setSidebarOpen(false);
                        }}
                        className={classNames(
                          window.location.pathname === item.href
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group w-full flex items-center pl-8 pr-2 py-2 text-sm font-medium rounded-md',
                        )}
                      >
                        <subItem.icon
                          className={classNames(
                            window.location.pathname === item.href
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden="true"
                        />
                        {subItem.name}
                      </button>
                    ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ),
      )}
    </nav>
  );
}
