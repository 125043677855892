import {ClockIcon, DocumentIcon, DesktopComputerIcon} from '@heroicons/react/solid';
import {MouseEventHandler, useState} from 'react';
import {classNames} from '../../HelperFunctions';
import { ITextWithIcon, TextWithIcon} from './Text';
import { IButton, } from './Button';
import API from '../../api/api';
import { CheckInformationModal, UserModal } from './Modal';

export interface IUser {
  active_time: number
  bundle_name: string
  checkout_datetime: Date
  display: string
  feature_code: string
  feature_version: string
  host: string
  license_handle: string
  overnight: boolean
  server_host: string
  server_port: string
  update_time: Date
  userid: string;
}

interface IUserList {
  users: IUser[];
  className?: string;
  refresh: () => Promise<void>
}

export function UserList({users, className, refresh}: IUserList) {
  async function removeUser(bundle_name: string, userId: string, host: string, display: string) {
    await API.removeUser(bundle_name, userId, host, display)
    refresh()
  }

  return (
    <div
      className={classNames(
        className,
        'overflow-y-auto bg-white shadow sm:rounded-md max-h-[calc(70vh)]'
      )}
    >
      <ul className="divide-y divide-gray-200 ">
        {users.map((user) => {
          return (
            <li key={user.userid + user.feature_code}>
              <ListItem
                title={user.userid}
                button={{
                  text: "Remove",
                  onClick: () => removeUser(user.bundle_name,user.server_host,user.server_port,user.userid)
                }}
                subtitles={[
                  {
                    text: user.feature_code,
                    icon: DocumentIcon,
                  },
                  {
                    text: user.display,
                    icon: DesktopComputerIcon
                  },
                  {
                    text: "" + user.checkout_datetime,
                    icon: ClockIcon
                  }
                ]}
                subbadge={{text: 'Last Updated: ' + user.update_time, icon: ClockIcon}}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export interface IFeature{
  timestamp: Date
  feature_code: string
  feature_version: string
  vendor: string
  license_type: string
  issued: number
  used: number
  users: IUser[]
}

interface IFeatureList {
  features: IFeature[];
  className?: string;
}

export function FeatureList({features, className}: IFeatureList) {
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [users, setUsers] = useState<IUser[]>([])

  
  return (
    <>
      <CheckInformationModal
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
        title='Users'
        descriptions={!users.length ? [{term: "No one using this license", description: ""}] :
          users.map((u) => { 
            return {term: u.userid, description: ""}
        })
      }
      />
      <div
        className={classNames(
          className,
          'overflow-y-auto bg-white shadow sm:rounded-md max-h-[calc(70vh)]'
        )}
      >
        <ul className="divide-y divide-gray-200 ">
          {features.map((feature) => {
            return (
              <li key={feature.feature_code}>
                <ListItem
                  onClick={() => {setUsers(feature.users); setInfoModalOpen(true)}}
                  title={feature.feature_code}
                  subtitles={[
                    {
                      text: "In use: " + feature.used + "/" + feature.issued,
                      icon: DesktopComputerIcon,
                    },
                  ]}
                  subbadge={{text: 'Last Updated: ' + feature.timestamp, icon: ClockIcon}}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export interface IBundle{
  timestamp: Date
  bundle_name: string
  bundle_version: string
  vendor: string
  license_type: string
  issued: number
  features: string[]
  used: number
  users: IUser[]
}

interface IBundleList {
  bundles: IBundle[];
  className?: string;
  refresh: () => Promise<void>
}

export function BundleList({bundles, className, refresh}: IBundleList) {
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [users, setUsers] = useState<IUser[]>([])
  

  async function removeUser(bundle_name: string, server_host: string, server_port: string, userid: string) {
    await API.removeUser(bundle_name, server_host, server_port, userid)
    refresh()
  }
  return (
    <>
      <UserModal
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
        title='Users'
        users={!users.length ? [{name: "No one using this license", checkout_datetime: "", button: {text: ""}}] :
          users.map((u) => { 
            return {name: u.userid, checkout_datetime: "" + u.checkout_datetime, button: {text: "Remove", onClick: () => removeUser(u.bundle_name,u.server_host,u.server_port,u.userid)}}
        })
      }
      />
      <div
        className={classNames(
          className,
          'overflow-y-auto bg-white shadow sm:rounded-md max-h-[calc(70vh)]'
        )}
      >
        <ul className="divide-y divide-gray-200 ">
          {bundles.map((bundle) => {
            return (
              <li key={bundle.bundle_name}>
                <ListItem
                  onClick={() => {setUsers(bundle.users); setInfoModalOpen(true)}}
                  title={bundle.bundle_name}
                  subtitles={[
                    {
                      text: "In use: " + bundle.used + "/" + bundle.issued,
                      icon: DesktopComputerIcon,
                    },
                  ]}
                  subbadge={{text: 'Last Updated: ' + bundle.timestamp, icon: ClockIcon}}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
interface IListItem {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  title: string;
  button?: IButton;
  subtitles?: ITextWithIcon[];
  subbadge?: ITextWithIcon;
}

export function ListItem({
  onClick,
  title,
  button,
  subtitles,
  subbadge,
}: IListItem) {
  return (
    <button onClick={onClick} className="w-full block hover:bg-gray-50">
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="truncate text-lg font-bold">{title}</p>
          <div className="ml-2 flex flex-shrink-0">
            {button && (
              <span className="font-bold text-indigo-700 hover:cursor-pointer hover:underline" onClick={button.onClick}>{button.text}</span>
              // <PrimaryButton
              //   text={button.text}
              //   onClick={button.onClick}
              // />
            )}
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            {subtitles && subtitles.map((sub, index) => {
              return (
                <div key={index}>
                  <TextWithIcon icon={sub.icon} text={sub.text} />
                </div>
              )
            })
          }            
            
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            {subbadge && (
              <TextWithIcon icon={subbadge.icon} text={subbadge.text} />
            )}
          </div>
        </div>
      </div>
    </button>
  );
}
