export interface IButton {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface IButtonWithIcon extends IButton {
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

export function SecondaryButton({text, onClick}: IButton) {
  return (
    <button
      type="button"
      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export function PrimaryButton({text, onClick}: IButton) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      {text}
    </button>
  );
}

export function PrimaryButtonWithIcon({
  text,
  onClick,
  icon: Icon,
}: IButtonWithIcon) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="mb-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <Icon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      <b>{text}</b>
    </button>
  );
}

export function SimpleIconButtonOnlyTextWhenScreenreader({
  text,
  onClick,
  icon: Icon,
}: IButtonWithIcon) {
  return (
    <button
      type="button"
      className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
      onClick={onClick}
    >
      <span className="sr-only">{text}</span>
      <Icon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
}

export function InlineButton({text, onClick}: IButton) {
  return (
    <button
      type="button"
      className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={onClick}
    >
      {text}
    </button>
  );
}
