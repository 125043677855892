import {useEffect, useState} from 'react';
import API from '../api/api';
import {HeadingOnDarkBackground} from '../components/layout/Heading';
import {InputWithLabel, SelectWithLabel} from '../components/layout/Input';
import {BundleList, IBundle} from '../components/layout/StackedList';
import Loading from '../components/Loading';

export default function Bundles() {
  const [bundles, setBundles] = useState<IBundle[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [option, setOption] = useState('All');
  const [loading, setLoading] = useState(false);

  async function getBundles() {
    setLoading(true);
    setBundles(await API.getBundles());
    setLoading(false);
  }

  useEffect(() => {
    getBundles();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <HeadingOnDarkBackground title='Bundles' className='mb-4' />
      <InputWithLabel
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        inputClassName='mb-4 p-2'
        placeholder='Search...'
      />
      <SelectWithLabel
        onChange={(e) => setOption(e.target.value)}
        options={['All', 'Used']}
        label={'Select Used/All bundles'}
      />
      <BundleList
        bundles={bundles.filter(
          (f) =>
            f.bundle_name.toLowerCase().includes(searchValue.toLowerCase()) &&
            ((option === 'Used' && f.used > 0) || option === 'All')
        )}
        refresh={getBundles}
      />
    </>
  );
}
