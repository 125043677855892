import {DesktopComputerIcon, DocumentIcon, UserIcon} from '@heroicons/react/outline';

export const navigation = [
  {name: 'Bundles', href: '/', icon: DesktopComputerIcon},
  {name: 'Features', href: '/features', icon: DocumentIcon},
  {name: 'Users', href: '/users', icon: UserIcon},
];

export const userNavigation = [
  {name: 'Your Profile', href: '#'},
  {name: 'Settings', href: '#'},
  {name: 'Sign out', href: '#'},
];
