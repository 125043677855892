import { useEffect, useState } from 'react';
import API from '../api/api';
import {HeadingOnDarkBackground} from '../components/layout/Heading';
import { InputWithLabel, SelectWithLabel } from '../components/layout/Input';
import { FeatureList, IFeature } from '../components/layout/StackedList';
export default function Features() {
  const [features, setFeatures] = useState<IFeature[]>([])
  const [searchValue, setSearchValue] = useState("")
  const [option, setOption] = useState("All");

  async function getFeatures() {
    setFeatures(await API.getFeatures())
  }

  useEffect(() => {
    getFeatures()
    
  }, [])
  

  return (
    <>
      <HeadingOnDarkBackground title="Features" className="mb-4" />
      <InputWithLabel value={searchValue} onChange={(e) => setSearchValue(e.target.value)} inputClassName="mb-4 p-2" placeholder='Search...' />
      <SelectWithLabel onChange={(e) => setOption(e.target.value) } options={["All","Used"]} label={'Select Used/All features'}/>
      <FeatureList features={features.filter((f) => f.feature_code.toLowerCase().includes(searchValue.toLowerCase()) && ((option==="Used" && f.used > 0) || option==="All"))}/>
    </>
  );
}
