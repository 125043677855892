import {classNames} from '../../HelperFunctions';

export interface ITextWithIcon {
  text: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

export function TextWithIcon({text, icon: Icon}: ITextWithIcon) {
  return (
    <p className="mr-2 mt-2 flex items-center text-sm text-gray-500 sm:mt-0 ">
      <Icon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      {text}
    </p>
  );
}

export interface ITextInBadge {
  text: string;
  textColor: string;
  backgroundColor: string;
}

export function TextInBadge({text, textColor, backgroundColor}: ITextInBadge) {
  return (
    <p
      className={classNames(
        textColor,
        backgroundColor,
        'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
      )}
    >
      {text}
    </p>
  );
}
