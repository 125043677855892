import axios, {AxiosResponse} from 'axios';

export default class API {
  static getUsers = async () => {
    const res: AxiosResponse = await axios.get(
      'https://licenseapi.wefabricate.app:444/users/'
    );


    if (res.data) {
      return res.data;
    }
  };

  static removeUser = async (
    bundle_name: string,
    server_host: string,
    server_port: string,
    userid: string
  ) => {
    await axios.delete(
      'https://licenseapi.wefabricate.app:444/bundles/?bundle_name=' +
        bundle_name +
        '&server_host=' +
        server_host +
        '&server_port=' +
        server_port +
        '&userid=' +
        userid
    );
  };

  static getFeatures = async () => {
    const res: AxiosResponse = await axios.get(
      'https://licenseapi.wefabricate.app:444/features/'
    );

    if (res.data) {
      return res.data;
    }
  };

  static getBundles = async () => {
    const res: AxiosResponse = await axios.get(
      'https://licenseapi.wefabricate.app:444/bundles/'
    );

    if (res.data) {
      return res.data;
    }
  };
}
