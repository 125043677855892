import {classNames} from '../../HelperFunctions';

interface IHeading {
  title: string;
  className?: string;
}

export function HeadingOnDarkBackground({title, className}: IHeading) {
  return (
    <div className={classNames(className, 'bg-gray-800 p-4 rounded-lg')}>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
            {title}
          </h2>
        </div>
      </div>
    </div>
  );
}
