import {DetailedHTMLProps, SelectHTMLAttributes} from 'react';
import {classNames} from '../../HelperFunctions';

interface IInputWithLabel
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  inputClassName?: string;
}

interface IInputWithLabelAndIcon extends IInputWithLabel {
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

export function InputWithLabel({
  inputClassName,
  placeholder,
  label,
  type,
  value,
  onChange,
  required,
  min,
  max,
  id,
  name,
  autoComplete,
}: IInputWithLabel) {
  return (
    <div>
      {label && (
        <label
          htmlFor={`input-${
            label || Math.ceil(Math.random() * 100000).toString()
          }`}
          className="mb-1 block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        min={min}
        max={max}
        id={id}
        name={name}
        autoComplete={autoComplete}
        className={classNames(
          inputClassName,
          'appearance-none hover:appearance-none block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
        )}
      />
    </div>
  );
}

export function InputWithLabelAndLeadingIcon({
  placeholder,
  label,
  type,
  value,
  onChange,
  required,
  icon: Icon,
}: IInputWithLabelAndIcon) {
  return (
    <div>
      {label && (
        <label
          htmlFor={`input-${
            label || Math.ceil(Math.random() * 100000).toString()
          }`}
          className="block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type={type}
          className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
        />
      </div>
    </div>
  );
}

interface ISelect
  extends ILabel,
    DetailedHTMLProps<
      SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    > {
  options: string[] | number[];
  optionTexts?: string[];
}

interface ILabel {
  label: string;
}

function Label({label}: ILabel) {
  return (
    <label
      htmlFor="event-type"
      className="block text-sm font-medium text-gray-900"
    >
      {label}
    </label>
  );
}

export function SelectWithLabel({
  disabled,
  onChange,
  defaultValue,
  options,
  optionTexts,
  label,
  value,
  className,
}: ISelect) {
  return (
    <div>
      <Label label={label} />
      <div className="mt-1">
        <select
          onChange={onChange}
          disabled={disabled}
          value={value}
          className={classNames(
            className,
            'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
          )}
          defaultValue={defaultValue}
        >
          {options.map((option, index) => {
            return (
              <option key={option} value={option}>
                {optionTexts ? optionTexts[index] : option}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
