import { useEffect, useState } from 'react';
import API from '../api/api';
import {HeadingOnDarkBackground} from '../components/layout/Heading';
import { UserList, IUser } from '../components/layout/StackedList';
import { InputWithLabel } from '../components/layout/Input';


export default function Users() {
  const [users, setUsers] = useState<IUser[]>([])
  const [searchValue, setSearchValue] = useState("")

  async function getUsers() {
    setUsers(await API.getUsers())
  }

  useEffect(() => {
    getUsers()
    
  }, [])

  return (
    <>
      <HeadingOnDarkBackground title="Users" className="mb-4" />
      <InputWithLabel value={searchValue} onChange={(e) => setSearchValue(e.target.value)} inputClassName="mb-4 p-2" placeholder='Search...' />
      <UserList users={users.filter((u) => u.userid.toLowerCase().includes(searchValue.toLowerCase()) || u.feature_code.toLowerCase().includes(searchValue.toLowerCase()))} refresh={getUsers}/>
    </>
  );
}
