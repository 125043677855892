// eslint-diable
export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

export function getMachineStatusBackground(
  status: string,
  tint: number,
): string {
  switch (tint) {
    case 100:
      switch (status) {
        case 'Running':
          return 'bg-green-100';
        case 'Idle':
          return 'bg-yellow-100';
        default:
          return 'bg-red-100';
      }
    // Break unnecessary but wanted by eslint => unreachable code
    // eslint-disable-next-line
    case 400:
      switch (status) {
        case 'Running':
          return 'bg-green-400';
        case 'Idle':
          return 'bg-yellow-400';
        default:
          return 'bg-red-400';
      }
    // Break unnecessary but wanted by eslint => unreachable code
    // eslint-disable-next-line
    default:
      throw new Error('Not implemented. Add tint to /src/HelperFunctions.ts');
  }
}

export function dateInput(date: Date) {
  const d = new Date(date);
  const dayZero = d.getDate() < 10 ? true : false;
  const monthZero = d.getMonth() + 1 < 10 ? true : false;
  return (
    d.getFullYear() +
    '-' +
    (monthZero ? '0' : '') +
    (d.getMonth() + 1) +
    '-' +
    (dayZero ? '0' : '') +
    d.getDate()
  );
}

export function dateTimeInput(date: Date) {
  const d = new Date(date);
  const monthZero = d.getMonth() + 1 < 10 ? true : false;
  const dayZero = d.getDate() < 10 ? true : false;
  const hourZero = d.getHours() < 10 ? true : false;
  const minuteZero = d.getMinutes() < 10 ? true : false;
  const secondZero = d.getSeconds() < 10 ? true : false;
  return (
    d.getFullYear() +
    '-' +
    (monthZero ? '0' : '') +
    (d.getMonth() + 1) +
    '-' +
    (dayZero ? '0' : '') +
    d.getDate() +
    'T' +
    (hourZero ? '0' : '') +
    d.getHours() +
    ':' +
    (minuteZero ? '0' : '') +
    d.getMinutes() +
    ':' +
    (secondZero ? '0' : '') +
    d.getSeconds()
  );
}
